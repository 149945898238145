import Head from 'next/head'
import React from 'react'

export default function CommonPage() {
	return (
		<>
			<Head>
				<title>Take A Bough</title>
				<meta name="description" content="" />
			</Head>

			<div style={{ marginTop: '20vh' }}>
				<p
					style={{
						textAlign: 'center',
						fontSize: 'calc(1em + 2vw)',
						lineHeight: 1,
					}}>
					Under construction
				</p>
				<div>
					<div id="638e46ef-c3a1-4af4-a919-88a8cc6fd968"></div>
					<div
						dangerouslySetInnerHTML={{
							__html: `<script src="https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_snippet.js" clienthub_id="638e46ef-c3a1-4af4-a919-88a8cc6fd968" form_url="https://clienthub.getjobber.com/client_hubs/638e46ef-c3a1-4af4-a919-88a8cc6fd968/public/work_request/embedded_work_request_form" />`,
						}}></div>
				</div>
			</div>
		</>
	)
}
